@import 'src/styles/main.scss';

.overlay {
  @include flex(center, center);

  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  flex: 1;
  backdrop-filter: brightness(35%);

  svg {
    @include size(58px);

    animation: spin 1.3s infinite linear;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
