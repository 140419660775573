@import 'src/styles/main.scss';

.app {
  @include flex($align: stretch, $direction: column);

  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.container {
  @include flex(center, stretch, column);

  flex: 1;
}
