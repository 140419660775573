@import 'src/styles/main.scss';

.sliderQuestion {
  color: black;
}

.error {
  display: block;
  width: 100%;
  margin-top: 3rem;
  font-size: 2rem;
  color: $red;
}
