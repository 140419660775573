@import 'src/styles/main.scss';

.container {
  @include error();

  position: relative;
  height: 100%;
}

.sub_container {
  @include flex(center, center, column);
}
