@import 'src/styles/main.scss';

.comment {
  width: 100%;
  padding: 12px 0 12px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
  color: $dark-grey;
  background: $white;
  border: 1px solid #99979e;
  border-radius: 8px;
  outline: none;
  resize: none;
  transition: $transition;

  &.error {
    border-color: $red;
  }

  &::placeholder {
    color: $light-grey;
  }

  &:disabled {
    cursor: not-allowed;
    background: $white-light;
  }
}
