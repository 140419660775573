@font-face {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/IQOS-Regular.ttf') format('truetype'),
    url('../assets/fonts/IQOS-Regular.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/IQOS-Bold.ttf') format('truetype'),
    url('../assets/fonts/IQOS-Bold.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: 'IQOS';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/IQOS-Light.ttf') format('truetype'),
    url('../assets/fonts/IQOS-Light.otf') format('opentype');
  font-display: block;
}




@font-face {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/GothamPro.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/GothamPro-Bold.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/GothamPro-Light.ttf') format('truetype');
  font-display: block;
}