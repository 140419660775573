@import 'src/styles/main.scss';

.radiogroup {
  @include flex(space-between, stretch);

  flex-wrap: wrap;

  @include sm() {
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
  }
}

.flag {
  @include size(24px);
  @include min-size(24px);

  cursor: pointer;
  position: relative;
  border: 1px solid $dark-grey;
  border-radius: 24px;
  transition: $transition;

  &_invalid {
    border-color: $red;
  }

  &__indicator {
    @include size(12px);
    @include min-size(12px);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    background: $dark-grey;
    border-radius: 24px;

    &_active {
      display: block;
    }
  }
}

.radio {
  @include flex($align: center);
  flex-wrap: nowrap;

  cursor: pointer;

  &--disabled {
    opacity: 0.5;

    & > .label,
    & > .flag {
      cursor: not-allowed;
    }
  }
}

.image {
  flex-shrink: 0;
  margin-left: 16px;
  width: 110px;
  max-height: 50px;
  background-color: #f6f4f0;
  border-radius: 8px;
  padding: 10px;
  overflow: hidden;

  img {
    @include size(100%);
    max-height: 30px;
    height: auto;
    width: auto;
    //margin-bottom: 10px;
    // margin-top: 10px;
  }

  &_device {
    width: 50px;
    height: 50px;
    margin-right: 12px;
    border-radius: 8px;
    padding: 0;

    img {
      max-height: 50px;
    }
  }
}

.label {
  margin-left: 12px;
  // width: 85%;
  color: $dark-grey;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}
