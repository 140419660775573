@import 'src/styles/main.scss';

.item {
  display: grid;
  grid-template-columns: 1fr;
  // gap: 12px;

  &:last-of-type {
    border: none;
  }

  &_with_image {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c4c4c4;
  }

  &_with_comment {
    border: none;
    grid-template-columns: 1.1fr 2.2fr;
    align-items: center;
  }

  @include sm() {
    flex: 1;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.control {
  //@include flex(center, flex-start, column);

  width: 100%;
  background: $white;
}
