@import 'src/styles/main.scss';

.radio_group {
  @include flex(space-between, center);
  @include error();

  position: relative;
  flex-wrap: wrap;
  align-items: stretch;

  @include sm() {
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
  }
}

.radio {
  display: flex;
  align-items: center;
  flex: 0 1 49%;
  margin-bottom: 2rem;
  background: $white;

  @include sm() {
    flex: 1;
    width: 100%;
  }
}

.radio__small_root {
  //padding: 1.75rem;

  //@include sm() {
  //  padding: 1.25rem;
  //}
}

.radio__small_font {
  //font-size: 1.7rem;
}

.radio__small__flag {
  //@include size(1.7rem);
}
