@import 'src/styles/main.scss';

.container {
  @include flex(space-between, center);

  position: fixed;
  z-index: 50;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 57px;
  padding: 0 22px;
  background: rgba(246, 244, 240, 0.77);
  backdrop-filter: blur(5px);
}

.arrow {
  &_left {
    transform: rotate(180deg);
  }

  &_container {
    @include flex(center);

    width: 100%;

    @include sm() {
      justify-content: flex-start;
    }

    &_right {
      @include sm() {
        justify-content: flex-end;
      }
    }
  }
}

.border {
  width: 1px;
  height: calc(100% - 24px);
  background: rgba(52, 48, 61, 0.17);
}
