@import 'src/styles/main.scss';

.time_picker_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid $aqua-blue;
  border-radius: 20px;
}

.time_picker {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2.5px 0;
  gap: 25px;
  width: 180px;
  border-radius: 10px;
  border-color: transparent;
  background-color: $aqua-blue;
}

.time_picker_item {
  font-family: $font;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: $white;
}

.time_picker_range_width {
  width: 110px;
}