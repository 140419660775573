.react-calendar {
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  padding: 10px, 24px, 10px, 24px;
  background: white;
  border-radius: 20px;
  border: 1px solid $aqua-blue;
  font-family: $font;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 56px;
  background-color: $aqua-blue;
  border-radius: 20px 20px 0 0;
}

.react-calendar__navigation button {
  width: 36px;
  background: none;

  &.react-calendar__navigation__prev-button {
    margin-left: 24px;
  }

  &.react-calendar__navigation__next-button {
    margin-right: 24px;
  }

  &.react-calendar__navigation__label {
    font-family: $font;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.31;
    letter-spacing: 0em;
    cursor: default;
    pointer-events: none;
  }
}

// .react-calendar__navigation button:disabled {
//   background-color: #f0f0f0;
// }

// .react-calendar__navigation button:enabled:hover,
// .react-calendar__navigation button:enabled:focus {
//   background-color: #e6e6e6;
// }

.react-calendar__month-view {
  padding: 4px 24px 22px 24px;

  .react-calendar__month-view__days {
    font-family: $font;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0em;
  }
}

.react-calendar__month-view__weekdays {
  justify-content: space-between;
  margin-bottom: 4px;
  text-align: center;
  text-transform: uppercase;
  font-family: $font;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
  color: $medium-grey;
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  flex: initial !important;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-inline-end: 0px;
  overflow: hidden;
}

// .react-calendar__month-view__weekdays__weekday {
//   padding: 0.5em;
// }

.react-calendar__month-view__days {
  column-gap: calc((100% - 36px * 7) / 6);
  row-gap: 4px;

  & button {
    flex: initial !important;
  }
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

// .react-calendar__month-view__days__day--weekend {
//   color: #d10000;
// }

.react-calendar__month-view__days__day--neighboringMonth {
  color: $dark-grey;
  opacity: 0.3;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  width: 36px;
  height: 36px;
  background: none;
  text-align: center;
  font-family: $font;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
  color: $dark-grey;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: $aqua-blue;
  color: $white;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

// .react-calendar__tile--now {
//   background-color: $grey-cloud;
//   color: $white;
//   border: 1px solid rgba(255, 255, 255, 0.5);
//   border-radius: 50%;
// }

// .react-calendar__tile--now:enabled:hover,
// .react-calendar__tile--now:enabled:focus {
//   background: #feb861;
// }

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background-color: $aqua-blue;
  color: $white;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

// .react-calendar__tile--active:enabled:hover,
// .react-calendar__tile--active:enabled:focus {
//   background: $aqua-blue;
// }

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}