@import 'src/styles/main.scss';

.arrow_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 4px 0;
}

.arrow_buttons {
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
