@import 'src/styles/main.scss';

.wrapper {
  @include error();

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  // gap: 12px;
  position: relative;

  @include sm() {
    @include flex(space-between, stretch, column);

    flex-wrap: nowrap;
  }
}
