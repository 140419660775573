:root {
    --aqua-blue: #00d1d2;
    --dark-grey: #34303d;
    --font: 'IQOS', sans-serif;
}


// Color Palette
$white: #fff;
$white-light: #e9e9e9;
$black: #000000;
$black-light: #282828;
$gray: #767676;
$primary: #d7d9e2;
$upload-white: #ebecf1;
$upload-text: ghostwhite;
$black-brown: #a26f26;

// New colors
$red: #c73636;
$aqua-blue: var(--aqua-blue);
$dark-grey: var(--dark-grey);
$milk-white: #fffdfb;
$grey-cloud: #b8b7bb;
$light-grey: #99979E;
$medium-grey: #7b7881;

//ZYN colors
$zyn-blue: #00A9E0;
$zyn-gray: #003B5E;

// Misc
$transition: 0.25s;

$font: var(--font);