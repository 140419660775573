@import 'src/styles/main.scss';

.flag {
  @include flex(center, center);
  @include size(24px);
  margin-right: 16px;
  flex-shrink: 0;

  border: 1px solid $dark-grey;
  border-radius: 3px;
  transition: $transition;

  img {
    @include size(100%);
  }

  &_checked {
    background: $dark-grey;
  }

  &_invalid {
    border-color: $red;
  }
}

.checkbox {
  @include flex($align: center);

  position: relative;
  cursor: pointer;

  &_disabled {
    opacity: 0.5;

    & > .label,
    & > .flag {
      cursor: not-allowed;
    }
  }
}

.image {
  flex-shrink: 0;
  // margin-left: 16px;
  width: 110px;
  max-height: 50px;
  background-color: #f6f4f0;
  border-radius: 8px;
  padding: 10px;
  overflow: hidden;

  img {
    @include size(100%);
    max-height: 30px;
    height: auto;
    width: auto;
    margin-right: 12px;
    //margin-bottom: 10px;
    // margin-top: 10px;
  }

  &_device {
    width: 50px;
    height: 50px;
    margin-right: 12px;
    border-radius: 8px;
    padding: 0;

    img {
      max-height: 50px;
    }
  }
}

.label {
  margin-left: 12px;
  // margin-right: 12px;
  width: 85%;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
  color: $dark-grey;
}
