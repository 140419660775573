@import 'src/styles/main.scss';

$mdx: 800px;

.container {
  @include error();

  position: relative;
  height: 100%;
}

.sub_container {
  display: grid;
  grid-gap: 50px;

  @media screen and (max-width: $mdx) {
    grid-template-columns: 1fr !important;
    grid-gap: 20px;
    justify-items: center;
  }

  &_invalid {
    &::after {
      content: url('../../../assets/images/error.svg');
      position: absolute;
      top: 14px;
      right: 15px;
      width: 20px;
      height: 20px;
    }
  }
}

.input_text {
  width: 100%;
  height: 50px;
  padding: 12px;
  font-size: 16px;
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  background: $white;
  border: 1px solid $light-grey;
  border-radius: 0.5rem;
  outline: none;
  resize: none;
  transition: $transition;

  &::placeholder {
    color: $light-grey;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &_focused {
    border-color: $dark-grey;
  }

  &_invalid {
    border: 2px solid $red;
  }

  &_disabled {
    opacity: 0.5;
    background: transparent;
    cursor: not-allowed;
  }
}

.question_image {
  width: 100%;
  height: 34vh;

  @media screen and (max-width: $mdx) {
    width: 80%;
    margin-bottom: 40px;
  }
}

.input_error_message {
  margin-top: 5px;
  width: 315px;

  font-family: $font;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0em;
  text-align: left;
}