@import "src/styles/main.scss";

.finished {
  @include flex($align: stretch, $direction: column);

  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 4rem;
  font-size: 3rem;
  background-color: $aqua-blue; //url(../../../assets/images/welcome-and-summary-background-desktop.png) no-repeat;
  background-size: cover;

  @include sm() {
    background-color: $aqua-blue; //url(../../../assets/images/welcome-and-summary-background.png) no-repeat;
    background-size: cover;
  }
}

.emojis {
  font-size: 4.5rem;
}

.content {
  @include flex(center, center, column);

  flex: 1;
}

.container {
  margin-top: 3rem;
  font-size: 4rem;
  font-weight: 100;
  text-align: center;
}
