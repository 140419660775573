@import 'src/styles/main.scss';

.range {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #c4c4c4;

  &:last-of-type {
    border-bottom: 0px;
  }
}
