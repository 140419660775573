@import 'src/styles/main.scss';

.summary {
  @include flex(flex-start, center, column);

  flex: 1;
  padding: 30px;
  background-color: $aqua-blue; //url(../../../assets/images/welcome-and-summary-background-desktop.png) no-repeat;
  background-size: cover;
  background-position: center;

  @include sm() {
    background-color: $aqua-blue; //url(../../../assets/images/welcome-and-summary-background-desktop.png) no-repeat;
    background-size: cover;
  }
}

.content {
  @include flex(center, center, column);

  max-width: 540px;
  margin: 0 auto;

  @include sm() {
    max-width: 100%;
    align-items: flex-start;
  }
}

.caption {
  margin-top: 20vh;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
  color: $dark-grey;

  @include sm() {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 42px;
    text-align: left;
  }

  a:visited,
  a:link {
    color: $black-brown;
  }
}

.description {
  align-self: center;
  max-width: 50%;
  font-size: 4rem;
  color: $black;
  letter-spacing: -0.099rem;
  font-weight: 100;
  margin-top: 3.3rem;

  @include sm() {
    max-width: 100%;
  }

  a:visited,
  a:link {
    color: $black-brown;
  }
}

.heart {
  font-size: 5.4rem;
  margin-top: 3rem;
}

.caption,
.description,
.heart {
  text-align: center;
}
