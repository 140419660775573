.css-yk16xz-control {
  border: 1px solid $light-grey;
  height: 50px !important;
}

.css-1pahdxg-control {
  border-color: $light-grey !important;
  border: 1px solid $dark-grey;
  height: 50px !important;
  box-shadow: none !important;
}

.css-g1d714-ValueContainer {
  padding: 5px 0 5px 20px !important;
}

.css-b8ldur-Input {
  color: $dark-grey;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
}

.css-1wa3eu0-placeholder {
  margin: 0 !important;
  padding: 0 !important;
  color: $light-grey !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-6q0nyr-Svg {
  width: 20px !important;
  height: 20px !important;
  color: $dark-grey !important;
}

.css-1uccc91-singleValue {
  color: $dark-grey;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
}

.css-tlfecz-indicatorContainer {
  margin-right: 10px !important;
}

.css-1gtu0rj-indicatorContainer {
  margin-right: 10px !important;
}

.css-26l3qy-menu {
  z-index: 100 !important;
  margin-top: 2px !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
  color: $dark-grey !important;
  background-color: $white !important;
}

.css-9gakcf-option {
  background-color: $light-grey !important;
  color: $dark-grey !important;
}

.css-1n7v3ny-option {
  background-color: $white !important;
}

.css-yt9ioa-option {
  &:active {
    background-color: $white-light !important;
    color: $dark-grey !important;
  }
}
