@import 'src/styles/main.scss';

.text {
  color: $dark-grey;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
}
.text2 {
  color: $dark-grey;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
}

.range {
  margin-top: 30px;
  margin-bottom: 25px;
  display: grid;
  gap: 8px;
}

.edge {
  @include flex(space-between, center);

  p {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
  }
}

.track {
  width: 100%;
  height: 6px;
  border-radius: 50px;

  &.error {
    border: 1px solid $red;
  }
}

.thumb__wrapper {
  position: relative;
}

.thumb {
  @include size(18px);

  position: relative;
  border-radius: 50%;
  background-color: $milk-white;
  border: 0.3px solid rgba(52, 48, 61, 0.3);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
  cursor: grab;
  outline: none;

  &:active {
    cursor: grabbing;
    outline: none;
  }

  &:before {
    outline: none;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    color: white;
    content: '|||';
    font-size: 6px;
  }

  &:after {
    @include size(18px);
    @include flex(center, center);

    outline: none;

    content: attr(data-content);
    position: absolute;
    bottom: calc(100% + 4px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    color: $dark-grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
  }
}

.checkbox__container {
  @include flex(flex-start, center, row);

  margin-top: -10px;

  &__input {
    @include size(24px);

    border: 1px solid $dark-grey;
  }

  p {
    margin-left: 10px;
    color: $dark-grey;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
  }
}
