@import "src/styles/main.scss";

.select {
  width: 100%;

  &__error {
    border: 2px solid $red;
    border-radius: 5px;
  }
}

.error {
  margin-top: 5px;
}