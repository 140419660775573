@import 'src/styles/main.scss';

.error {
  display: block;
  width: 100%;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  color: $red;

  &_with_padding {
    padding: 0 20px;
  }
}
