@import 'src/styles/main.scss';

.variant {
  @include flex(space-between, center, row);

  flex-wrap: wrap;

  &:not(:last-of-type) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c4c4c4;
  }

  &_one_before_from_last,
  &:last-of-type {
    // border-bottom: 1px solid #c4c4c4;
    margin-bottom: 30px;
    // padding-bottom: 20px;
  }

  &_with_comment {
    grid-template-columns: 1fr;
    border: none;
  }

  &_checkbox {
    width: -webkit-calc(100% - 50px);

    &_comment {
      width: auto;
    }

    &_flag {
      margin-right: 0;
      flex-shrink: 0;
    }

    &_label {
      display: block;
      margin-left: 16px;
      margin-right: 0;
    }
  }

  &_flag {
    flex-shrink: 0;
  }

  &_input {
    width: 50px;
    font-family: $font;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: $dark-grey;
    font-size: 16px;

    input {
      padding: 0;
      text-align: center;
    }
  }

  &_comment {
    width: 69.5%;
    height: 50px;
  }
}

.comment_field {
  display: flex;
  justify-content: space-between;
  width: 100%;
}