@import './variables.scss';

@mixin flex($justify: flex-start, $align: flex-start, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin min-size($size) {
  min-width: $size;
  min-height: $size;
}

@mixin error {
  .error {
    display: block;
    width: 100%;
    margin-top: 3rem;
    font-size: 2rem;
    color: $red;
  }
}
