@import 'src/styles/main.scss';

.input {

  width: 100%;
  height: 50px;
  padding: 12px 0 12px 20px;
  //margin-top: 25px;

  font-family: $font;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  font-size: 16px;
  border: 1px solid $light-grey;
  border-radius: 5px;
  outline: none;
  transition: $transition;

  &:focus {
    border: 1px solid $dark-grey;
  }

  &_error {
    border: 2px solid $red;
  }
}