@import 'src/styles/main.scss';

.input {
  width: 100%;
  height: 50px;
  padding: 12px 0 12px 20px;
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  font-size: 16px;
  text-align: left;
  border: 1px solid $light-grey;
  background-color: $white;
  border-radius: 5px;
  outline: none;
  transition: $transition;
  color: $dark-grey;

  &::placeholder {
    color: $light-grey;
  }

  &:disabled {
    cursor: not-allowed;

    &>* {
      cursor: not-allowed;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.focused {
  border-color: $dark-grey;
}

.error {
  border: 2px solid $red;
}