@import 'src/styles/main.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @include sm() {
    @include flex($direction: column);
  }
}
