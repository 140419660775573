@import 'src/styles/main.scss';

.field {
  margin-bottom: 20px;
}

.title {
  margin: 0 0 15px;
  font-size: 16px;
  font-weight: 600;
  color: $dark-grey;
}

.input {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  outline: none;
  border: 1px solid $dark-grey;
  border-radius: 5px;
}

.cities {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.radio {
  margin-bottom: 10px;

  &_textarea {
    font-family: $font;
    margin-top: 10px;
    width: 100%;
    padding: 8px;
    outline: none;
  }
}

.textarea {
  font-family: $font;
  width: 100%;
  padding: 8px;
  outline: none;
}

.image {
  position: relative;

  &_close {
    @include size(28px);

    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}