@import "src/styles/main.scss";

.container {
  width: 100vw;
  height: 100vh;
  background-color: $aqua-blue; //url(../../../assets/images/welcome-and-summary-background-desktop.png) no-repeat;
  background-size: cover;

  @include sm() {
    background-color: $aqua-blue; //url(../../../assets/images/welcome-and-summary-background-desktop.png) no-repeat;
    background-size: cover;
  }
}

.not_found {
  @include flex(center, center, column);

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.caption {
  font-size: 6rem;
  text-align: center;
}
.captionsmall {
  font-size: 3rem;
  text-align: center;
}
