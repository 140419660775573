@import './variables.scss';

#onetrust-pc-sdk .ot-pc-logo {
    display: none;
    opacity: 0;
}

#ot-pc-title,
#ot-pc-desc,
#ot-category-title,
.ot-cat-header,
#onetrust-consent-sdk #onetrust-pc-sdk p {
    color: $dark-grey !important;
}

.ot-btn-container {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    padding: 10px !important;
}

#accept-recommended-btn-handler,
#onetrust-pc-btn-handler,
.onetrust-close-btn-handler {
    font-family: $font !important;
    --box-shadow-color: transparent !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    cursor: pointer !important;
    display: inline-block !important;
    vertical-align: middle !important;
    text-overflow: ellipsis !important;
    transition: background .2s, box-shadow .3s ease-out, color .2s !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    position: relative !important;
    z-index: 1 !important;
    will-change: background, bos-shadow, color !important;
    font-weight: 400 !important;
    height: 44px !important;
    width: fit-content !important;
    margin: 0 auto;
    padding-top: 10px !important;
    border: 2px solid #f6f4f0 !important;
    border-radius: 50px !important;
    font-size: 16px !important;
    line-height: 100% !important;
    color: #f6f4f0 !important;
    text-decoration: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

#accept-recommended-btn-handler {
    width: 200px !important;
    background-color: $dark-grey !important;
    margin-bottom: 30px !important;
}

.save-preference-btn-handler {
    min-width: 220px !important;
}

#onetrust-pc-btn-handler {
    background-color: $dark-grey !important;
}

#onetrust-accept-btn-handler,
.ot-pc-refuse-all-handler {
    --box-shadow-color: transparent !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    cursor: pointer !important;
    display: inline-block !important;
    vertical-align: middle !important;
    max-width: 100% !important;
    text-overflow: ellipsis !important;
    transition: background .2s, box-shadow .3s ease-out, color .2s !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    position: relative !important;
    z-index: 1 !important;
    will-change: background, bos-shadow, color !important;
    font-weight: 400 !important;
    width: 135px !important;
    height: 44px !important;
    background: #f6f4f0 !important;
    border-radius: 50px !important;
    font-family: $font !important;
    font-size: 16px !important;
    line-height: 100% !important;
    color: $dark-grey !important;
    text-align: center !important;
    margin: 0 !important;
    padding: 0 !important;
}

#onetrust-pc-btn-handler:hover:before,
.onetrust-close-btn-handler:hover:before,
#onetrust-accept-btn-handler:hover:before,
#accept-recommended-btn-handler:hover::before {
    transform: translate(-10%, -50%) skew(-30deg) !important;
    opacity: 1 !important;
}

#onetrust-accept-btn-handler:hover {
    outline: none !important;
    border: none !important;
}

#onetrust-pc-btn-handler:hover,
#accept-recommended-btn-handler:hover {
    outline: none !important;
    border: none !important;
    color: $dark-grey !important;
}

#onetrust-pc-btn-handler:before,
.onetrust-close-btn-handler:before,
#onetrust-accept-btn-handler::before,
#accept-recommended-btn-handler::before {
    display: block !important;
    content: "" !important;
    position: absolute !important;
    top: 50% !important;
    left: 0 !important;
    width: 120% !important;
    height: 120% !important;
    z-index: -1 !important;
    transition: transform .25s ease-out !important;
    transform: translate(-101%, -50%) skew(0deg) !important;
    opacity: 1 !important;
    background: $aqua-blue !important;
}

#onetrust-button-group {
    display: flex !important;
    justify-content: space-between !important;

    & button {
        width: 150px !important;
    }
}

.onetrust-close-btn-handler {
    background-color: $dark-grey !important;
}

.ot-pc-footer-logo {
    display: none;
    opacity: 0;
}

#onetrust-policy-text {
    font-family: $font !important;
    text-align: center !important;
    font-size: 12px !important;
    line-height: 140% !important;
    color: #fff !important;
    margin: 0 !important;
}

#onetrust-policy {
    margin: 0 0 15px !important;
}

#onetrust-consent-sdk {
    z-index: 2147483648 !important;
}

.ot-sdk-container {
    position: fixed !important;
    z-index: 1001 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    background-color: $dark-grey !important;
    padding-top: 13px !important;
    padding-bottom: 18px !important;
    min-height: 135px !important;
}

.ot-sdk-row {
    max-width: 315px !important;
    margin: 0 auto !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk *:focus,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-vlst-cntr>a:focus {
    outline: none !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk .privacy-notice-link {
    color: $dark-grey !important;
}

#onetrust-group-container {
    width: 100% !important;
}

@media (min-width: 550px) {
    #onetrust-button-group-parent {
        width: 100% !important;
        position: initial !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .ot-sdk-row {
        max-width: 500px !important;
    }

    #onetrust-button-group {
        display: flex !important;
        justify-content: center !important;
        gap: 40px !important;
    }

    #onetrust-policy {
        min-height: 80px !important;
    }



}

@media (min-width: 1000px) {
    .ot-sdk-container {
        padding-bottom: 0px !important;
    }
}