@import 'src/styles/main.scss';

.container {
  width: 100%;
}

.select {
  width: 100%;
  height: 54px;
  border-radius: 5px;

  &_error {
    border: 2px solid $red;
  }
}
