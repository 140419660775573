@import 'src/styles/main.scss';

.scale {
  @include flex($align: stretch, $direction: column);

  position: relative;
}

.marks {
  @include flex(space-between, center);

  padding: 1rem 0 2rem 0;

  & > .mark {
    &:not(:first-child) {
      margin-left: 1rem;

      @include sm() {
        margin-left: 0.5rem;
      }
    }

    @include sm() {
      margin: 0.5rem;
    }
  }

  @include sm() {
    display: grid;
    grid-template-columns: repeat(4, auto);
  }
}

.mark {
  @include flex(center, center);
  @include size(9.5rem);
  @include min-size(9.5rem);

  padding: 1rem;
  font-size: 4rem;
  color: $black;
  letter-spacing: -0.09rem;
  background: $white;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: $transition;

  @include sm() {
    @include size(7.5rem);
    @include min-size(7.5rem);

    font-size: 3rem;
  }

  &_invalid {
    border-color: $red;
  }

  &__selected {
    color: $white;
    background: $black;
  }
}

.hints {
  @include flex(space-between, center);

  margin-top: 2rem;

  @include sm() {
    display: none;
  }
}

.hint {
  font-size: 2rem;
  color: $black;

  &__mobile {
    display: none;
    font-size: 2rem;
    padding: 0 0.7rem;

    @include sm() {
      display: block;
    }
  }

  &__last {
    position: absolute;
    bottom: -1rem;
    right: 26%;
    text-align: right;
  }
}
