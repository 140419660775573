@import 'react-calendar/dist/Calendar.css';
@import 'src/styles/main.scss';

$mdx: 800px;

.container {
  @include error();

  position: relative;
  height: 100%;
}

.sub_container {
  @include flex(center, center, column);
}
